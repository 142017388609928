import React from "react"

import SEO from "components/seo"
import Layout from "components/layout"
import { GlobalCover } from "components/global/cover/cover"
import { AccessHeadline } from "components/pages/access/headline"
import { AccessStatistics } from "components/pages/access/detail/statistics"

const AccessDetail = ({ pageContext, location }) => {
	const lang = pageContext.langKey
	const {
		cover,
		headline,
		statistics
	} = lang === "en"
			? pageContext?.data?.accessDetailPage
			: pageContext?.data?.translation?.accessDetailPage
			|| pageContext?.data?.accessDetailPage

	const locationsData = pageContext.accessLocations
	const { seo, backgroundColor } = lang === "en"
		? pageContext.data
		: pageContext.data.translation
		|| pageContext.data

	const SEODefaultImg = cover.image.sourceUrl
	const bgColor = backgroundColor?.backgroundColor?.color

	return (
		<Layout theme={bgColor || "light"} path={location.pathname} lang={lang}>
			<SEO
				title={seo.title}
				desc={seo.metaDesc}
				fbImg={SEODefaultImg}
				fbTitle={seo.opengraphTitle}
				fbDesc={seo.opengraphDescription}
				url={location.href}
				twitterTitle={seo.twitterTitle}
				twitterDesc={seo.twitterDescription}
				twitterImg={SEODefaultImg}
			/>
			<GlobalCover data={cover} theme={bgColor || "light"} breadcrumb={location.pathname} />
			<AccessHeadline data={headline} wrapperClassName="mw-lg" className="pt-5 pb-2" />
			<AccessStatistics data={statistics} options={locationsData} path={location.pathname} />
		</Layout>
	)
}

export default AccessDetail