import React, { useEffect, useState } from "react"

import { Select } from "components/anti/forms/forms"
import { useScrollAnim } from "src/components/hooks/hooks"
import BGSustain1 from "assets/img/bg_about_sustain_1.png"
import BGSustain2 from "assets/img/bg_about_sustain_2.png"
import BGSustain3 from "assets/img/bg_about_sustain_3.png"

export const AccessStatistics = ({ data, options, path }) => {
    const [trigger, anim] = useScrollAnim()

    const [locations, setLocations] = useState([])
    const [dataLoc, setDataLoc] = useState()
    const [list, setList] = useState([])

    const handleChange = (id, args) => {
        setLocations(args)
    }

    useEffect(() => {
        options = options.nodes?.map(op => ({
            value: op.slug,
            label: op.name,
        }))
        setList(options)
        setLocations(options[0])
    }, [])

    useEffect(() => {
        options?.nodes?.map(item => {
            if (locations.value === item.slug) {
                if (path.indexOf('toll-road') >= 0) {
                    setDataLoc(item.accessLocationsPage.tollRoadAccess)
                } else if (path.indexOf('train-station') >= 0) {
                    setDataLoc(item.accessLocationsPage.trainStationsAccess)
                } else if (path.indexOf('bus-shuttle-station') >= 0) {
                    setDataLoc(item.accessLocationsPage.busShuttleAccess)
                }
            }
        })
    }, [locations])

    return (
        <section ref={trigger}>
            <div className="container mw-lg">
                <div className="pb-5 row">
                    <div className="col-md-4">
                        <Select
                            variant="underline"
                            id="locations"
                            name="locations"
                            value={locations}
                            options={list}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="pb-5">
                <div className="container mw-lg">
                    <h5 className={`${anim(1)}`}>{dataLoc?.title}</h5>
                    <ul>
                        {dataLoc?.text?.map((list, i) => {
                            return (
                                <li key={i} className={`${anim(2 + i)}`}>
                                    {list.text}
                                </li>
                            )
                        })}
                    </ul>
                    {dataLoc?.addInfo && (
                        <>
                            <h5 className={`${anim(4)}`}>{dataLoc?.addInfo.title}</h5>
                            <p className={`${anim(5)}`}>{dataLoc?.addInfo.text}</p>
                        </>
                    )}
                </div>
            </div>
            <div className="pt-5 pb-4 global-marketingGallery">
                <img className="bg-image curve-1" src={BGSustain1} alt="curve-1" />
                <img className="bg-image curve-2" src={BGSustain2} alt="curve-2" />
                <img className="bg-image curve-3" src={BGSustain3} alt="curve-3" />
                <div className="container mw-md">
                    <div className="row">
                        {data?.map((statistic, i) => {
                            return (
                                <div className="col-6 col-md-3" key={i}>
                                    <h2 className={`${anim(6 + i)}`}>{statistic?.text}</h2>
                                    <p className={`${anim(7 + i)}`}>{statistic?.label}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}